<template>
    <div class="main">
        <div class="text-h3" style="margin-bottom: 30px">
            库存列表
        </div>
        <v-divider></v-divider>

        <v-btn
                color="primary"
                fab
                dark
                @click="openBox"
                style="position: absolute; right: 80px; top: 92px"
        >
            <v-icon>mdi-playlist-plus</v-icon>
        </v-btn>
        <v-card
                class="mx-auto card"
        >
            <v-simple-table style="margin-top: 70px">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            序号
                        </th>
                        <th class="text-left">
                            车辆状态
                        </th>
                        <th class="text-left">
                            车系
                        </th>
                        <th class="text-left">
                            车型
                        </th>
                        <th class="text-left">
                            颜色
                        </th>
                        <th class="text-left">
                            VIN
                        </th>
                        <th class="text-left">
                            内饰
                        </th>
                        <th class="text-left">
                            备注
                        </th>
                        <th class="text-left">
                            状态
                        </th>
                        <th class="text-left">
                            操作
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="(item, index) in desserts"
                            :key="item.name"
                    >
                        <td>{{ item.id }}</td>
                        <td>{{ item.car_status }}</td>
                        <td>{{ item.car_series }}</td>
                        <td>{{ item.car_type }}</td>
                        <td>{{ item.car_color }}</td>
                        <td>{{ item.car_vin }}</td>
                        <td>{{ item.Interior }}</td>
                        <td>{{ item.descript }}</td>
                        <td>{{ item.status ? '可销售' : '不可销售' }}</td>
                        <td>
                            <v-btn @click="editOpenBox(index)" small color="primary">编辑</v-btn>
                            <v-btn @click="changeStatus(index)" style="margin-left: 10px" small color="primary">{{item.status ? '不可销售' : '可销售'}}</v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <Dialog @clickOk="clickOk" @cancel="cancel" :dialog="dialog" :title="edit_id === '' ? '添加库存' : '修改库存' ">
            <template v-slot:content>
                <v-row>
                    <v-col md="6" cols="12" sm="12">
                        <v-select
                                :items="['在途', '在库', '在线等']"
                                v-model="template_desserts.car_status"
                                outlined
                                dense
                                label="车辆状态"
                        ></v-select>
                    </v-col>
                    <v-col md="6" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.car_series"
                                label="车系"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.car_type"
                                label="车型"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.car_color"
                                label="颜色"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.car_vin"
                                label="VIN"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.Interior"
                                label="内饰"
                        ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="template_desserts.descript"
                                label="备注"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>
        </Dialog>
        <v-snackbar
                color="red"
                v-model="snackbar"
        >
            请将内容填写完整
        </v-snackbar>
    </div>
</template>
<script>
    import Dialog from '@/components/dialog'
    export default {
        name: 'stockList',
        data: () => ({
            snackbar: false,
            dialog: false,
            edit_id: '',
            template_desserts: {
                car_status: '',
                car_series: '',
                car_type: '',
                car_color: '',
                car_vin: '',
                Interior: '',
                status: 1,
                descript: ''
            },
            desserts: [
                {
                    id: 1,
                    car_status: '在库',
                    car_series: '蓝鸟',
                    car_type: 'ZFCR2',
                    car_color: '琥珀金/CAM',
                    car_vin: 'LGBR4DE48KR154350',
                    Interior: '深内饰Z/Z',
                    status: 1,
                    descript: '备注'
                },
                {
                    id: 2,
                    car_status: '在线等',
                    car_series: '蓝鸟',
                    car_type: 'UMC6',
                    car_color: '琥珀金/CAM',
                    car_vin: 'LGBH72E01KY748837',
                    Interior: '深内饰G/G',
                    status: 0,
                    descript: '备注'
                },
                {
                    id: 3,
                    car_status: '在库',
                    car_series: '蓝鸟',
                    car_type: 'UMC6',
                    car_color: '珠光白/QAB',
                    car_vin: 'LGBH72E01KY748837',
                    Interior: '深内饰Z/Z',
                    status: 1,
                    descript: '备注'
                },
                {
                    id: 4,
                    car_status: '在库',
                    car_series: '蓝鸟',
                    car_type: 'UMC6',
                    car_color: '琥珀金/CAM',
                    car_vin: 'LGBH72E01KY748837',
                    Interior: '深内饰Z/Z',
                    status: 1,
                    descript: '备注'
                },
                {
                    id: 5,
                    car_status: '在途',
                    car_series: '楼兰',
                    car_type: 'UMC6',
                    car_color: '珠光白/QAB',
                    car_vin: 'LGBR4DE48KR154350',
                    Interior: '深内饰G/G',
                    status: 1,
                    descript: '备注'
                },
                {
                    id: 6,
                    car_status: '在库',
                    car_series: '蓝鸟',
                    car_type: 'UMC6',
                    car_color: '珠光白/QAB',
                    car_vin: 'LGBH72E01KY748837',
                    Interior: '深内饰Z/Z',
                    status: 0,
                    descript: '备注'
                },
                {
                    id: 7,
                    car_status: '在途',
                    car_series: '楼兰',
                    car_type: 'ZFCR2',
                    car_color: '珠光白/QAB',
                    car_vin: 'LGBR4DE48KR154350',
                    Interior: '深内饰Z/Z',
                    status: 1,
                    descript: '备注'
                },
                {
                    id: 8,
                    car_status: '在库',
                    car_series: '蓝鸟',
                    car_type: 'UMC6',
                    car_color: '珠光白/QAB',
                    car_vin: 'LGBH72E01KY748837',
                    Interior: '深内饰Z/Z',
                    status: 1,
                    descript: '备注'
                }
            ]
        }),
        methods: {
            changeStatus(index) {
                this.desserts[index].status = this.desserts[index].status === 1 ? 0 : 1;
            },
            editOpenBox(index) {
                this.template_desserts = JSON.parse(JSON.stringify(this.desserts[index]))
                this.dialog = true
                this.edit_id = index
            },
            openBox() {
                this.edit_id = ''
                this.template_desserts = {
                    car_status: '',
                    car_series: '',
                    car_type: '',
                    car_color: '',
                    car_vin: '',
                    Interior: '',
                    status: 1,
                    descript: ''
                }
                this.dialog = true
            },
            cancel() {
                this.dialog = false
            },
            clickOk() {
                let flag = false
                Object.keys(this.template_desserts).forEach(x => {
                    if (this.template_desserts[x] === '') {
                        console.log(x)
                        flag = true
                    }
                })
                if (flag) {
                    this.snackbar = true
                    setTimeout(() => {
                        this.snackbar = true
                    }, 3000)
                    return
                }
                if (this.edit_id === '') {
                    this.desserts.push(JSON.parse(JSON.stringify(this.template_desserts)))
                    this.dialog = false
                }else {
                    this.desserts.splice(this.edit_id, 1 , JSON.parse(JSON.stringify(this.template_desserts)))
                    this.dialog = false
                }
            }
        },
        components: {
            Dialog
        }
    }
</script>
<style lang="scss" scoped>
    .main {
        padding: 30px;
    }
</style>
